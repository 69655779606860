<template>
  <div :class="componentStyleState">
    <label class="BaseDebtorRating__label fw-med">{{ rating }}</label>
  </div>
</template>

<script>

export default {
  name: 'BaseDebtorRating',

  props: {
    rating: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
  },

  computed: {
    componentStyleState () {
      return {
        BaseDebtorRating: true,
        'BaseDebtorRating--enormous': this.size === 'enormous',
        'BaseDebtorRating--large': this.size === 'large',
        'BaseDebtorRating--medium': this.size === 'medium',
        'BaseDebtorRating--small': this.size === 'small',
        BaseDebtorRating__A: this.rating === 'A',
        BaseDebtorRating__B: this.rating === 'B',
        BaseDebtorRating__C: this.rating === 'C',
        BaseDebtorRating__D: this.rating === 'D',
        BaseDebtorRating__F: this.rating === 'F'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.BaseDebtorRating
  +flex($align: center, $justify: center)
  $block: &
  border-radius: $border-radius
  flex-shrink: 0

  &__A,
  &__B,
    background-color: rgba($success, .1)

    #{$block}__label
      color: $success

  &__C,
  &__D
    background-color: rgba(#FF902A, .1)

    #{$block}__label
      color: #FF902A

  &__F
    background-color: rgba($danger, .1)

    #{$block}__label
      color: $danger

  &__label
    user-select: none

  &--enormous
    height: rem(153px)
    width: rem(153px)

    #{$block}__label
      font-size: rem(100px)
      margin-top: rem(-10px) // hack to center such a large character

  &--large
    height: rem(37px)
    width: rem(37px)

    #{$block}__label
      font-size: 1rem // Matches design, IDFK

  &--medium
    height: rem(25px)
    width: rem(25px)

    #{$block}__label
      font-size: rem(14px)

  &--small
    height: rem(18px)
    width: rem(18px)

    #{$block}__label
      font-size: rem(10px)
</style>
